<template>
  <div>
    <b-alert v-model="isDraftAvailable" v-height-fade.appear variant="warning">
      <div class="alert-body">
        <b-row>
          <b-col class="align-self-center">
            Do you want to restore the draft data?
          </b-col>
          <b-col class="align-self-center text-right">
            <b-button @click="removeDraft" variant="outline-danger" class="mr-2"
              >Ignore</b-button
            >
            <b-button @click="restoreDraft" variant="primary">Restore</b-button>
          </b-col>
        </b-row>
      </div>
    </b-alert>
    <b-tabs>
      <b-tab active title="English">
        <b-card title="Tender Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Description">
          <b-row>
            <b-col cols="12">
               <b-form-group label="Description">
                <b-form-input
                  v-model="page.description"
                  placeholder="Description"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Tenders">
          <b-form
            class="repeater-form"
            @submit.prevent="repeatTender(null)"
          >
           
          <b-col cols="7">
            <b-form-group label="Tenders" label-for="tenders">
              <v-select
                multiple
                label="pageTitle"
                v-model="page.tenderList"
                :reduce="
                  (tenderItem) => {
                    return { tenderItem: tenderItem._id };
                  }
                "
                placeholder="Select Tenders"
                :options="tenders"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
          </b-form>
        </b-card>
      </b-tab>
      <b-tab title="Hindi">
         <b-card title="Tender Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.translation.hi.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

       <b-card title="Description">
          <b-row>
            <b-col cols="12">
               <b-form-group label="Description">
                <b-form-input
                  v-model="page.translation.hi.description"
                  placeholder="Description"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
         <b-card title="Tenders">
         <b-form
            class="repeater-form"
            @submit.prevent="repeatTender('hi')"
          >
           
          <b-col cols="7">
            <b-form-group label="Tenders" label-for="tenders">
              <v-select
                multiple
                label="pageTitle"
                v-model="page.translation.hi.tenderList"
                :reduce="
                  (tenderItem) => {
                    return { tenderItem: tenderItem._id };
                  }
                "
                placeholder="Select Tenders"
                :options="tenders"
              />
            </b-form-group>
          </b-col>
          </b-form>
        </b-card>
    </b-tab>
      <b-tab title="Marathi">
        <b-card title="Tender Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.translation.mr.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
       <b-card title="Description">
          <b-row>
            <b-col cols="12">
               <b-form-group label="Description">
                <b-form-input
                  v-model="page.translation.mr.description"
                  placeholder="Description"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
       <b-card title="Tenders">
         <b-form
            class="repeater-form"
            @submit.prevent="repeatTender('mr')"
          >
           
          <b-col cols="7">
            <b-form-group label="Tenders" label-for="tenders">
              <v-select
                multiple
                label="pageTitle"
                v-model="page.translation.mr.tenderList"
                :reduce="
                  (tenderItem) => {
                    return { tenderItem: tenderItem._id };
                  }
                "
                placeholder="Select Tenders"
                :options="tenders"
              />
            </b-form-group>
          </b-col>
          </b-form>
        </b-card>
    </b-tab>
    </b-tabs>
     <b-card>
      <b-row>
        <b-col cols="6">
          <b-button size="lg" @click="saveDraft" block variant="outline-info"
            >Create Draft</b-button
          >
        </b-col>
        <b-col cols="6">
          <b-button size="lg" @click="savePage" block variant="primary"
            >Save Page</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BTabs,
  BTab,
  BButton,
  BFormTextarea,
  BImg,
  BAlert,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import { heightFade } from "@core/directives/animations";
import { getUserToken } from "@/auth/utils";
import vSelect from "vue-select";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import axios from "axios";

export default {
  directives: {
    "height-fade": heightFade,
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BTabs,
    BTab,
    BButton,
    BFormTextarea,
    ComponentMediaSelector,
    BImg,
    BAlert,
  },
  data() {
    return {
      isDraftAvailable: false,
      tenders: [],
      page: {
        pageTitle: "",
        description: "",
        tenderList: [],
        translation: {
          hi: {
            pageTitle: "",
            description: "",
            tenderList: []
          },
          mr: {
            pageTitle: "",
            description: "",
            tenderList: []
          },
        },
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    getTenderItems() {
       axios
        .get(`${process.env.VUE_APP_SERVER_URL}/tenders/tenderItems/all`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          console.log('tender res', response.data)
          this.tenders = response.data

        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Couldn't load Tender Data");
        });
    },
    removeTenderItem(lang = undefined, index) {
      if (lang) {
        this.page.translation[lang].tenderList.splice(index, 1);
      } else {
        this.page.tenderList.splice(index, 1);
      }
    },
    repeatTender(lang = undefined) {
      if (lang) {
        this.page.translation[lang].tenderList.push({
          tenderItem: ""
        });
      } else {
        this.page.tenderList.push({
          tenderItem: ""
        });
      }
    },
    saveDraft() {
      localStorage.setItem("tender-page-data", JSON.stringify(this.page));
      this.makeToast("success", "Success", `Draft Saved!`);
    },
    restoreDraft() {
      const page = localStorage.getItem("tender-page-data");
      this.page = JSON.parse(page);
      console.log(this.page)
    },
    removeDraft() {
      localStorage.removeItem("tender-page-data");
      this.isDraftAvailable = false;
    },
    getPage() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/tenders/tenderPage/get-all`)
        .then((response) => {
            this.page = response.data
            console.log('page', response.data)
            const tenderList = response.data.tenderList
            ? response.data.tenderList.map((tenderList) => {
                return {
                  tenderItem: tenderList.tenderItem._id,
                };
              })
            : [];
        this.page.tenderList = tenderList;
            const tenderListMr = response.data.translation.mr.tenderList
            ? response.data.translation.mr.tenderList.map((tenderList) => {
                return {
                  tenderItem: tenderList.tenderItem._id,
                };
              })
            : [];
        this.page.translation.mr.tenderList = tenderListMr;
        const tenderListHi = response.data.translation.hi.tenderList
            ? response.data.translation.hi.tenderList.map((tenderList) => {
                return {
                  tenderItem: tenderList.tenderItem._id,
                };
              })
            : [];
        this.page.translation.hi.tenderList = tenderListHi;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    savePage() {
      console.log(this.page);
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/tenders/tenderPage/save`, this.page, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
       .then((response) => {
              this.makeToast(
                "success",
                "Success",
                `${this.page.pageTitle} has been added successfully!`
              );
            })
            .catch((error) => {
              this.makeToast(
                "danger",
                "Error",
                "Error while adding the tender!"
              );
              console.log(error.response);
            });
    },
  },
  mounted() {
     this.getTenderItems();
    if (localStorage.getItem("tender-page-data")) {
      this.isDraftAvailable = true;
    }
    this.getPage();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>